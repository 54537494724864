import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import { CartPrices } from '@sus-core/components/cart/CartPrices';
import { CartItemsList } from './CartItemsList';
import { CartAddresses } from './CartAddresses';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import {
  CHECKOUT_STEP_NUMBERS,
  useTracking,
} from '@sus-core/hooks/tracking/useTracking';

export function Cart() {
  const { isCartEmpty, content } = useCartService();
  const stepTracked = useRef(false);
  const tracking = useTracking();
  useEffect(() => {
    if (!isCartEmpty && content && !stepTracked.current) {
      tracking.checkoutStep(CHECKOUT_STEP_NUMBERS.CART, content.items);
      stepTracked.current = true;
    }
  }, [isCartEmpty, content]);

  return (
    <section
      className={clsx(
        'flex flex-col md:flex-row md:items-baseline gap-8',
        PAGE_CONTAINER_CSS
      )}>
      <div className="flex-3 px-10 pb-10 pt-6 rounded-lg shadow">
        <h3>Ihre Bestellung</h3>
        <div>Übersicht ihrer Artikel im Warenkorb.</div>
        <CartItemsList />
      </div>

      <div className="flex-2 px-10 pb-10 pt-6 rounded-lg shadow text-13 flex flex-col gap-y-3">
        <h3>Bestellübersicht</h3>

        <CartPrices />

        <CartAddresses />

        {!isCartEmpty && (
          <div className="flex flex-col gap-y-3 mt-3">
            <Link
              to="/checkout/adresse"
              className="bg-primary text-white text-center p-4 rounded-lg">
              Zur Kasse
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}

import Layout from '@sus-core/components/scaffolding/Layout';

import clsx from 'clsx';
import React from 'react';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';

import { Cart } from '../shared/components/cart/Cart';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';

export default function CartPage({ location, pageContext }: SusPageProps) {
  return (
    <PageProvider
      data={{
        pageType: 'Checkout',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        showBreadcrumbs
        currentPageName="Warenkorb"
        location={location}
        meta={{ title: 'Schrank und Stuhl - Warenkorb' }}>
        <h1 className={clsx(PAGE_CONTAINER_CSS, 'mb-10')}>Warenkorb</h1>
        <Cart />
      </Layout>
    </PageProvider>
  );
}
